<template>
    <div class="limit-control-root">
        <span class="ticker">{{ limit.ticker }}</span>
        <div>
            <input ref="input" class="input" :placeholder="limit.placeholder" v-model="value" v-on:keyup.esc="blur"
                v-on:keyup.enter="blur" />
        </div>
    </div>
</template>
  
<script>
import { DonationLimit } from "../../code/classes/LimitsHelper";

export default {
    props: {
        limit: { type: DonationLimit },
    },
    data() {
        return {
            valid: "",
        };
    },
    computed: {
        value: {
            get() {
                return this.limit.value;
            },
            set(value) {
                this.limit.SetValue(value);
            },
        },
    },
    methods: {
        revert() {
            this.limit.Revert();
            this.blur();
        },
        blur() {
            this.$refs.input.blur();
        },
    },
};
</script>
  
<style scoped lang="scss">
.limit-control-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 70%
}

.limit-control-root:not(:last-child) {
    margin-bottom: 14px;
}

.ticker {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.input {
    padding: 0px 24px;
    height: 44px;
    width: 340px;
    border: none;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.10);
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

@media (min-width:1800px) {
    .limit-control-root {
        max-width: 74%;
    }

    .ticker,
    .input {
        font-size: 16px;
    }

    .input {
        width: 371px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .limit-control-root {
        max-width: 72%;
    }
    .input {
        width: 272px;
    }
}

@media (max-width: 680px) {
    .limit-control-root {
        max-width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
    .ticker {
        margin-bottom: 10px;
        font-size: 12px;
    }
    .input {
        width: 220px;
        font-size: 12px;
        padding: 0px 19px;

    }
}
</style>
  