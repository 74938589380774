<template>
    <div class="proxy" :class="classes" v-if="!setting?.hidden">
        <SettingSliderWidget v-if="setting?.type === 'SettingSlider'" :setting="setting" :readOnly="readOnly" />
        <SettingBoolWidget v-else-if="setting?.type === 'SettingBool'" :setting="setting" :readOnly="readOnly" />
    </div>
</template>
  
<script>
import { SettingBase } from "../../code/classes/SettingBase";
import SettingSliderWidget from "@/components/donationSettings/settingSliderWidget.vue"
import SettingBoolWidget from "@/components/donationSettings/settingBoolWidget.vue";

export default {
    components: {
        SettingSliderWidget,
        SettingBoolWidget
    },
    props: {
        readOnly: { type: Boolean, default: false },
        setting: { type: SettingBase },
    },
    computed: {
        classes() {
            const classes = [];
            if (this.setting?.uninitialized) classes.push("uninitialized");
            if (this.setting?.disabled) classes.push("disabled");
            return classes.join(" ");
        },
    },
};
</script>
  
<style scoped lang="scss">
.uninitialized,
.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.proxy {
    display: flex;
    flex-direction: column-reverse;
}
</style>
  