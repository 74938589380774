<template>
    <div class="limits-root" :class="classes" v-if="$account.limits.limits.length">

        <div class="title">{{ $t('donationSettings.title') }}</div>
        <div class="description">{{ $t('donationSettings.description') }}</div>

        <LimitControl v-for="limit of $account.limits.limits" :key="limit.ticker" :limit="limit" />

        <div class="proxy-list">
            <SettingProxy v-for="setting in accountSettings.allSettings" :key="setting.displayName" :setting="setting" />
        </div>

        <button class="save btn"
            :class="{ 'disabled': (!accountSettings.dirty) && !($account.limits.canSave && !$account.limits.pendingOperation) }"
            @click="save">
            <i class="save-icon"></i>
            <span>{{ $t('buttons.save') }}</span>
        </button>

        <Pop v-if="isSaving" :popText="popText" />

        
    </div>
</template>
  
<script>
import { AccountSettings } from "../../code/classes/AccountSettings";
import LimitControl from "@/components/donationSettings/limitsControl.vue";
import SettingProxy from "@/components/donationSettings/settingProxy.vue";
import Pop from '@/components/account/settingPop.vue'
import i18n from '../../i18n';

export default {
    props: {
        accountSettings: { type: AccountSettings },
    },

    components: {
        LimitControl,
        SettingProxy,
        Pop
    },
    data: () => ({
        isSaving: false,
        popText: i18n.t('donationSettings.success')

    }),

    computed: {
        classes() {
            const disabled = this.$account.limits.pendingOperation || this.$account.limits.loading;
            return disabled ? "disabled" : undefined;
        },
    },
    methods: {
        async save() {
            if (this.isSaving) return;
            this.isSaving = true;
            this.$account.limits.Commit();
            this.accountSettings
                .Save()
                .then(() => {
                    setTimeout(() => {
                        this.isSaving = false;
                    }, 1000)
                })
                .catch((error) => {
                    console.error(error);
                    setTimeout(() => {
                        this.isSaving = false;
                    }, 1000)
                });
        },

    },
}
</script>
  
<style scoped lang="scss">
.limits-root {
    padding: 40px 25px;
    max-width: 719px;
    border-radius: 14px;
    background: #130E25;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}

.title {
    margin-bottom: 14px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.description {
    margin-bottom: 14px;
    color: #A2A2A2;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.proxy-list {
    display: flex;
    flex-direction: column-reverse;
}

.save {
    margin-top: 60px;
    margin-left: auto;
    cursor: pointer;
    padding: 15px 44px 15px 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
}

.save-icon {
    width: 16px;
    height: 16px;
    margin-right: 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M2 8.00002L6.00028 12L14 4' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

@media (min-width:1800px) {
    .limits-root {
        max-width: 750px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .limits-root {
        max-width: 600px;
    }

    .title {
        font-size: 16px;
    }

    .save {
        margin-top: 50px;
    }
}

@media (max-width: 680px) {
    .limits-root {
        padding: 36px 21px;
        max-width: 258px;
    }

    .title {
        font-size: 14px;
    }

    .description {
        font-size: 10px;
    }

    .save {
        margin-top: 40px;
        margin-left: 0;
        width: 100%;
        height: 40px;
    }
}
</style>
  