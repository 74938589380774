<template>
    <div class="slider">
        <div class="title">{{ $t('donationSettings.sliderTitle') }}</div>
        <div class="slider-content">
            <div class="slider-content__description">{{ $t('donationSettings.sliderDescription') }}</div>
            <input :disabled="readOnly" v-model="value" type="range" ref="slider" :id="setting.name" :min="setting.minValue"
                :max="setting.maxValue" :step="setting.step" />
            <input class="limitInput" type="number" :min="setting.minValue" :max="setting.maxValue" v-model="value"
                :step="setting.step">
        </div>
        <p class="count-description">{{ $t('donationSettings.sliderCountDescription') }}</p>
    </div>
</template>
  
<script>
import { SettingSlider } from "../../code/classes/SettingSlider";

export default {
    props: {
        readOnly: { type: Boolean, default: false },
        setting: { type: SettingSlider },
    },
    watch: {
        value() {
            this.updateTrackColor()
        }
    },

    mounted() {
        this.updateTrackColor()
    },

    computed: {
        value: {
            get() {
                return this.setting.value;
            },
            set(value) {
                this.setting.SetValue(value);
            },
        },
    },

    methods: {
        updateTrackColor() {
            const slider = this.$refs.slider;
            const trackProgress = (slider.value - slider.min) / (slider.max - slider.min);
            const trackColor = `linear-gradient(90deg, #D574DE ${trackProgress * 20}%, #432D64 ${trackProgress * 82.23}%)`;
            
            slider.style.background = trackColor;
        }
    }
};
</script>
  
<style scoped lang="scss">
.slider {
    margin-top: 40px;
}

.title {
    margin-bottom: 21px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.slider-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slider-content__description {
    max-width: 83px;
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#messageLimit {
    position: relative;
    width: 388px;
    height: 15px;
    border-radius: 100px;
    -webkit-appearance: none;
    appearance: none;
    background: #432D64;
}

#messageLimit:hover,
#messageLimit:focus {
    outline: none;
}

#messageLimit::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 100px;
}

#messageLimit::-moz-range-track {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

#messageLimit::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    top: 2px;
    outline: 9px solid #FFF;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%);
    cursor: pointer;
}

#messageLimit::-moz-range-thumb {
    outline: 9px solid #FFF;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%);
    cursor: pointer;
}


.count {
    position: absolute;
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.limitInput {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 44px;
    color: #FFF;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    border: none;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.10);
}

.limitInput::-webkit-outer-spin-button,
.limitInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.count-description {
    margin-top: 33px;
    margin-left: 130px;
    color: #A2A2A2;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

@media (min-width:1800px) {
    .slider {
        margin-top: 60px;
    }

    .slider-content__description,
    .limitInput {
        font-size: 16px;
    }

    #messageLimit {
        width: 419px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .slider {
        margin-top: 24px;
    }

    #messageLimit {
        width: 300px;
    }

    .count-description {
        margin-left: 118px;
    }
}

@media (max-width: 680px) {
    .title {
        font-size: 14px;
    }

    .slider-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .slider-content__description {
        max-width: 100%;
        margin-bottom: 14px;
        font-size: 12px;
    }

    #messageLimit {
        width: 100%;
    }

    .limitInput {
        margin-top: 20px;
        width: 100%;
        height: 40px;
    }

    .count-description {
        margin-top: 14px;
        margin-left: 0;
    }
}
</style>
  