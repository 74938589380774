<template>
    <div class="bool">
        <div class="title">{{ $t('donationSettings.switherTitle') }}</div>
        <div class="bool-content">
            <div class="Links">{{ $t('donationSettings.remove') }}</div>
            <div class="switch">
                <p class="switch-variant">{{ $t('donationSettings.no') }}</p>
                <input class="switcher" :disabled="readOnly" v-model="value" type="checkbox" :id="setting.name" />
                <p class="switch-variant">{{ $t('donationSettings.yes') }}</p>
            </div>
        </div>
        <p class="switch-description">{{ $t('donationSettings.switherDescription') }}</p>
    </div>
</template>
  
<script>
import { SettingBool } from "../../code/classes/SettingBool";

export default {
    props: {
        readOnly: { type: Boolean, default: false },
        setting: { type: SettingBool },
    },
    computed: {
        value: {
            get() {
                return this.setting.value;
            },
            set(value) {
                this.setting.SetValue(value);
            },
        },
    },
};
</script>
  
<style scoped lang="scss">
.bool {
    margin-top: 40px;
}

.title {
    margin-bottom: 24px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bool-content {
    display: flex;
    align-items: center;
}

.Links {
    max-width: 89px;
    margin-right: 49px;
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.switch {
    display: flex;
    align-items: center;
}

.switch-variant {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.switcher {
    position: relative;
    margin: 0px 20px;
    width: 56px;
    height: 25px;
    border-radius: 25px;
    background: #A0AEC0;
    appearance: none;
    transition: 0.3s;
}

.switcher:checked {
    background: linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%);
}

.switcher::before {
    content: '';
    position: absolute;
    top: 2.78px;
    left: 2.8px;
    width: 19.6px;
    height: 19.444px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.switcher:checked:before {
    left: 33.6px;
}

.switch-description {
    margin-top: 14px;
    margin-left: 137px;
    color: #A2A2A2;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

@media (min-width:1800px) {
    .bool {
        margin-top: 60px;
    }

    .Links,
    .switch-variant {
        font-size: 16px;
    }

    .Links {
        max-width: 102px;
        margin-right: 30px;
    }

    .switch-description {
        margin-left: 130px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .bool {
        margin-top: 24px;
    }
    .Links {
        margin-right: 30px;
    }

    .switch-description {
        margin-left: 118px;
    }
}

@media (max-width: 680px) {
    .title {
        font-size: 14px;
        margin-bottom: 14px;
    }

    .bool-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .Links {
        max-width: none;
        margin-right: 0;
        margin-bottom: 16px;
    }

    .Links,
    .switch-variant {
        font-size: 12px;
    }

    .switch-description {
        margin-left: 0;
    }
}
</style>
  